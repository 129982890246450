import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Clock from 'react-live-clock'
import { toast } from "react-toastify"
// import io from 'socket.io-client';
import axios from "axios";
import { JIRA_LINK } from "../../utils/constants";


const Dashboard = ({ auth, weather }) => {
    const [user] = useState(auth.user);
    const [notifications, setNotifications] = useState([])
    const [message, setMessage] = useState("")
    const [is12Format, setIs12Format] = useState(false)

    // const socket = io('http://localhost:5001');

    // useEffect(() => {
    //     socket.on('message', (message) => {
            // console.log("message:: ", message)
            // toast(message, {
            //     position: "top-right",
            //     autoClose: false,
            // })
    //     });
    // }, [socket]);

    const getPriorityNotification = async () => {
        await axios.get(`/api/getPriorityNotifications?userId=${user?.role === 2 ? user?.id : user?.id}`).then((res) => {
            setNotifications(res?.data?.notifications)
        })
    }

    useEffect(() => {
        console.log("message......", message)
        getPriorityNotification()

    }, [message])

    const handleCrossButtonClick = async (message) => {
        await axios.post("/api/updatePriorityNotifications", {
            messageId: message._id
        }).then((res) => {
            setMessage(res?.data?.message)
            toast.success(res?.data?.message)
        }).catch((err) => {
            console.log("ERR:>", err)
        });
    };

    const handleClockFormat = () => {
        setIs12Format(prev => !prev)
        localStorage.setItem("is12Format", !is12Format);;
    }

    useEffect(() => {
        const is12Format = localStorage.getItem("is12Format")
        setIs12Format(is12Format === "true" ? true : false)
    }, [])

    return (
        <>
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">

                            <div className="row px-2">
                                <div className="col-sm-4 col-lg-3 pt-2">
                                    <div className="card bg-primary text-white shadow-lg">
                                        <div className="card-body" style={{ position: 'relative' }}>
                                            <div
                                                className="clock-format"
                                                onClick={handleClockFormat}
                                            >{is12Format ? "24H" : "12H"}</div>
                                            <h1>
                                                {
                                                    is12Format &&
                                                    <Clock format={`hh:mm:ss`} interval={1000} ticking={true} />
                                                }
                                                {
                                                    !is12Format &&
                                                    <Clock format={`HH:mm:ss`} interval={1000} ticking={true} />
                                                }

                                            </h1>
                                            <Clock
                                                format={'dddd, MMMM Do, YYYY'}

                                                className="card-text" />
                                        </div>
                                    </div>
                                    {weather?.weather &&
                                        <div className="card text-black weather-card shadow-lg mt-3">
                                            <div className="card-body">
                                                <div className="icon-temp">
                                                    <img
                                                        width={80}
                                                        height={80}
                                                        src={weather?.weather?.iconURL}
                                                        alt={weather?.weather?.desc}
                                                    />
                                                    {weather?.weather?.temp ? parseInt(weather?.weather?.temp) : 0}
                                                    <sup className="deg">°C</sup>
                                                </div>
                                                <div className="des-wind">
                                                    <p>{weather?.weather?.desc?.toUpperCase()}</p>
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                                <div className="col-sm-8 col-md-8 col-lg-9">
                                    <div className="row">
                                        {notifications?.map((msg) => {
                                            return (
                                                <div className="col-sm-6 col-md-6 col-lg-4 p-sm-2">
                                                    <div
                                                        className="card bg-danger text-white shadow-lg"
                                                        style={{ height: '100%' }}
                                                    >
                                                        <div className="card-body">
                                                            <button
                                                                type="button"
                                                                className="close"
                                                                aria-label="Close"
                                                                onClick={() => handleCrossButtonClick(msg)}
                                                            >
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                            <h5 className="card-title">
                                                                <a
                                                                    className="text-white"
                                                                    href={`${JIRA_LINK}/browse/${msg?.type}`}
                                                                    target="_blank"
                                                                >
                                                                    {msg?.type}
                                                                </a>
                                                            </h5>
                                                            <p className="card-text">{msg?.message}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    weather: state.weather,
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
