import React, { useState, useEffect, Fragment, useMemo, useRef } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactTable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { JIRA_LINK } from "../../utils/constants";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import Select from "../partials/Select";
import Datepicker from "../partials/Datepicker";
import { dateFormat } from "../../utils/dateFormat";

const priorities = [
	{ value: "Medium", label: "Medium" },
	{ value: "High", label: "High" },
	{ value: "Highest", label: "Highest" },
]

const tasksStatuses = [
	{ value: "Selected for Development", label: "Selected for Development" },
	{ value: "In Progress", label: "In Progress" },
	{ value: "Ready For Demo", label: "Ready For Demo" },
	{ value: "Ready for QA", label: "Ready for QA" },
	{ value: "Defects", label: "Defects" }
]

const Tasks = ({ auth }) => {
	const [date, setDate] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection'
		}
	]);
	const [records, setRecords] = useState([]);
	const [users, setUsers] = useState([])
	const [selectedUsers, setSelectedUsers] = useState([])
	const [selectedPriorities, setSelectedPriorities] = useState([])
	const [selectedStatus, setSelectedStatus] = useState([])
	const [search, setSearch] = useState("")
	const [activePage, setActivePage] = useState(1)
	const [pageSize, setPageSize] = useState(20)
	const [total, setTotal] = useState(0)
	const [showDatepicker, setShowDatepicker] = useState(false)
	const showDatepickerRef = useRef()

	const columns = useMemo(() => [
		{
			key: "type",
			text: "Ticket",
			className: "type",
			align: "left",
			sortable: true,
			cell: record => (
				<a
					href={`${JIRA_LINK}/browse/${record?.type}`}
					target="_blank"
				>
					{record?.type}
				</a>
			),
		},
		{
			key: "message",
			text: "Description",
			className: "message",
			align: "left",
			sortable: true,
		},
		{
			key: "priority",
			text: "Priority",
			className: "priority",
			align: "left",
			sortable: true,
		},
		{
			key: "ticket_status",
			text: "Status",
			className: "ticket_status",
			align: "left",
			sortable: true,
		},
		{
			key: "user_id",
			text: "Email",
			className: "user_id",
			align: "left",
			sortable: true,
			cell: record => (
				<span>{record?.user_id?.email}</span>
			),
		},
		{
			key: "createdAt",
			text: "createdAt",
			className: "createdAt",
			align: "left",
			sortable: true,
			cell: record => (
				<span>{new Date(record?.createdAt).toDateString()}</span>
			),
		},
		{
			key: "updatedAt",
			text: "updatedAt",
			className: "updatedAt",
			align: "left",
			sortable: true,
			cell: record => (
				<span>{new Date(record?.updatedAt).toDateString()}</span>
			),
		}
	], [auth?.user]);

	const config = {
		page_size: pageSize,
		length_menu: [20, 30, 50],
		filename: "Tasks",
		no_data_text: 'No task found!',
		button: {
			excel: true,
			print: true,
			csv: true,
		},
		language: {
			length_menu: "Show _MENU_ result per page",
		},
		show_length_menu: true,
		show_filter: true,
		show_pagination: false,
		show_info: false,
	};

	const getData = () => {
		let query = { page: activePage, perPage: pageSize, search }
		let body = {}

		if (auth?.user?.role === 2) {
			body.users_id = [auth.user?.id]
		} else if (selectedUsers?.length > 0) {
			body.users_id = selectedUsers?.map(itm => itm.value)
		}

		if (selectedPriorities?.length > 0) {
			body.priority = selectedPriorities?.map(itm => itm.value)
		}

		if (selectedStatus?.length > 0) {
			body.ticket_status = selectedStatus?.map(itm => itm.value)
		}

		if (date) {
			body.date = date[0]
		}

		axios
			.post(`/api/tasks?${new URLSearchParams(query).toString()}`, body)
			.then(res => {
				setRecords(res.data?.tasks);
				setTotal(res.data?.total);
			})
			.catch(error => {
				console.error("Error fetching data:", error.message);
			});
	};

	const getUsers = () => {
		axios
			.get(`/api/user-data`)
			.then(res => {
				setUsers(res.data?.map(itm => ({ value: itm?._id, label: itm?.email })) || []);
			})
			.catch(error => {
				console.error("Error fetching data:", error.message);
			});
	};

	const onChange = pageData => {
		setSearch(pageData?.filter_value)
		setPageSize(parseInt(pageData?.page_size))

	};

	const sendMessage = async (text) => {
		try {
			const res = await axios.post(
				"/api/notify-slack",
				{
					text,
					username: auth?.user?.name,
				}
			);

			if (res.data?.ok) {
				toast.success("Notification sent")
			}
		} catch (error) {
			console.log(error);
		}
	}

	const handleClickOutside = (event) => {
		if (showDatepickerRef.current && !showDatepickerRef.current.contains(event.target)) {
			setShowDatepicker(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		getUsers();
	}, []);

	useEffect(() => {
		getData();
	}, [search, selectedUsers, date, selectedStatus, selectedPriorities, pageSize, activePage]);

	return (
		<div>
			<Navbar />
			<div className="d-flex" id="wrapper">
				<Sidebar />

				<div id="page-content-wrapper">
					<div className="container-fluid backdrop-cont">
						<button className="btn btn-link mt-3" id="menu-toggle">
							<FontAwesomeIcon icon={faList} />
						</button>
						<h1 className="mt-2 text-primary">Tasks List</h1>

						<br />
						<div>
							<div className="row">
								<div className="col-12 col-md-6 col-lg-4">
									{auth?.user?.role === 1 &&
										<div className="select_wrapper" >
											<label>Users: </label> {" "}
											<Select
												allOptions={users}
												selectedOptions={selectedUsers}
												setSelectedOptions={setSelectedUsers}
											/>
										</div>
									}
								</div>
								<div className="col-12 col-md-6 col-lg-4">
									<div className="select_wrapper">
										<label>Priority: </label> {" "}
										<Select
											allOptions={priorities}
											selectedOptions={selectedPriorities}
											setSelectedOptions={setSelectedPriorities}
										/>
									</div>
								</div>
								<div className="col-12 col-md-6 col-lg-4">
									<div className="select_wrapper">
										<label>Task status: </label> {" "}
										<Select
											allOptions={tasksStatuses}
											selectedOptions={selectedStatus}
											setSelectedOptions={setSelectedStatus}
										/>
									</div>
								</div>
							</div>

							<div className="select_wrapper date_wrapper mt-2">
								<label>Date: </label> {" "}
								<span
									onClick={() => setShowDatepicker(true)}
									className="selected_date"
								>{dateFormat(date[0].startDate)} - {dateFormat(date[0].endDate)}</span>

								<div ref={showDatepickerRef}>
									{showDatepicker && <Datepicker
										date={date}
										setDate={setDate}
									/>}
								</div>
							</div>
						</div>
						<br />

						<ReactTable
							config={config}
							records={records}
							columns={
								auth?.user?.role === 1 ?
									[
										...columns,
										{
											key: "action",
											text: "Action",
											className: "action",
											width: 100,
											align: "left",
											sortable: false,
											cell: record => (
												<Fragment>
													<button
														onClick={() => sendMessage(`${record?.type} ${record?.message}`)}
														className="btn btn-primary btn-sm"
														style={{ marginRight: '5px' }}>
														<i className="fa fa-slack"></i>
													</button>
												</Fragment>
											),
										}
									] : columns
							}
							onChange={onChange}
						/>
						<div className="d-flex justify-content-center my-2">
							<Pagination
								activePage={activePage}
								itemsCountPerPage={pageSize}
								totalItemsCount={total}
								pageRangeDisplayed={5}
								onChange={e => setActivePage(e)}
								nextPageText="Next"
								prevPageText="Prev"
								firstPageText="First"
								lastPageText="Last"
								itemClass="cursor-pointer position-relative inline-flex items-center px-3 py-2
								text-sm font-medium text-gray-500 border"
								activeClass="border-active"
								activeLinkClass="font-bold"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Tasks.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
	records: state.records,
});

export default connect(mapStateToProps)(Tasks);