import {
  WEATHER_ADD
} from "../actions/types";

const initialState = {
  weather: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case WEATHER_ADD:
      return {
        weather: action.payload
      };
    default:
      return state;
  }
}
