import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import store from './store';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';

import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../node_modules/jquery/dist/jquery.min';
import '../node_modules/popper.js/dist/popper';
import './App.css';

import Login from './components/auth/Login';
import Register from './components/auth/Register';
import NotFound from './components/layout/NotFound';
import Dashboard from './components/pages/Dashboard';
import PrivateRoute from './components/private-route/PrivateRoute';
import User from './components/pages/Users';
import Axios from 'axios';
import { WEATHER_ADD } from './actions/types';
import Tasks from './components/pages/Tasks';
import { ToastContainer } from 'react-toastify';

const App = () => {
  const [image, setImage] = useState(null)
  const dispatch = useDispatch()

  const getUserConfig = async () => {
    try {
      const queryParams = {};

      if (navigator.geolocation) {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position?.coords;

        if (latitude && longitude) {
          queryParams.lat = latitude?.toFixed(4);
          queryParams.lon = longitude?.toFixed(4);
        }
      } else {
        console.error('Geolocation is not supported by your browser');
      }

      const res = await Axios.get(`/api/userConfig?${new URLSearchParams(queryParams).toString()}`);

      if (res?.data) {
        store.dispatch({
          type: WEATHER_ADD,
          payload: res?.data?.weather
        })

        setImage(res?.data?.image.url);
        document.body.style.backgroundImage = `url(${res.data.image.url})`;
        document.body.style.backgroundPosition = 'bottom';
      }
    } catch (error) {
      console.error('Error in userConfig:', error.message);
    }
  };

  useEffect(() => {
    getUserConfig()
    if (localStorage.jwtToken) {
      const token = localStorage.jwtToken;
      setAuthToken(token);
      const decoded = jwt_decode(token);

      store.dispatch(setCurrentUser(decoded));
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        store.dispatch(logoutUser());
        window.location.href = '/login';
      } else {
        // getUserConfig()
      }
    }
  }, []);

  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Switch>
          <PrivateRoute
            exact
            path="/dashboard"
            component={Dashboard}
            allowedRoles={[0, 1, 2]}
          />
          <PrivateRoute
            exact
            path="/tasks"
            component={Tasks}
            allowedRoles={[0, 1, 2]}
          />
          <PrivateRoute
            exact
            path="/users"
            component={User}
            allowedRoles={[0, 1]}
          />
        </Switch>
        <Route exact path="*" component={NotFound} />
      </Switch>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme={"light"}
      />

    </div>
  );
};

export default App;
