import React, { useState } from "react";
import ReactSelect, { components } from "react-select";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex "
  };

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />{" "}
      {children}
    </components.Option>
  );
};

export default function Select({
  selectedOptions,
  setSelectedOptions,
  allOptions
}) {

  return (
    <ReactSelect
      defaultValue={selectedOptions}
      isMulti
      className="react-select-container"
      classNamePrefix="react-select"
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={(options) => {
        if (Array.isArray(options)) {
          setSelectedOptions(options);
        }
      }}
      options={allOptions}
      components={{
        Option: InputOption
      }}
    />
  );
}
