import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import React from 'react';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import { addYears, endOfDay, endOfYear, isSameDay, startOfYear } from 'date-fns';

export default function Datepicker({ date, setDate }) {
  return (
    <DateRangePicker
      onChange={item => setDate([item.selection])}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={date}
      direction="horizontal"
      staticRanges={[
        ...defaultStaticRanges,
        {
          label: "This Year",
          range: () => ({
            startDate: startOfYear(new Date()),
            endDate: endOfDay(new Date())
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          }
        },
        {
          label: "Last Year",
          range: () => ({
            startDate: startOfYear(addYears(new Date(), -1)),
            endDate: endOfYear(addYears(new Date(), -1))
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          }
        }
      ]}
    />
  )
}

