import axios from "axios";
import {
    GET_ERRORS,
    USER_UPDATE
} from "./types";
import { toast } from "react-toastify";

export const addUser = (userData, getData, hideModal) => dispatch => {
    axios
        .post("/api/user-add", userData)
        .then(res => {
            hideModal()
            toast.success("User added");
            getData()
        }
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const updateUser = (userData, getData, hideModal, shouldDispatch) => dispatch => {
    axios
        .post("/api/user-update", userData)
        .then(res => {
            hideModal()
            toast.success("User updated");
            getData()

            if (shouldDispatch) {
                dispatch({
                    type: USER_UPDATE,
                    payload: res,
                })
            }
        }
        ).catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
