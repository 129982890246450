import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store';
import $ from "jquery";
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router >
    </Provider >
    , document.getElementById('root')
);

$("#menu-toggle").click(function () {
    $("#wrapper").toggleClass("toggled");
});

$('.modal[data-reset="true"]').on('shown.bs.modal', () =>
    $("input[name != 'timestamp']").val(''));

$('.modal').on('shown.bs.modal', () =>
    $('input[data-reset-input="true"]').val(''));
